import AboutCircle from '../components/projects/AboutCircle';
import classes from './AboutPage.module.css';
import cv from '../otherFiles/CV KOKKALIS KONSTANTINOS.pdf';
function AboutPage(props) {
  return (
    <section id="about">
      <div className={classes.aboutPageContent}>
        <div>
          <a
            className={classes.resumeLink}
            href={cv}
            // download="KOKKALIS KONSTANTINOS CV.pdf"
          >
            Resume
          </a>
        </div>
      </div>
      {/* <div className={classes.aboutPageContent}>
        <div className={classes.circleBox}>
          <div className={classes.experienceCircle}>
            <p>Experience</p>
          </div>
        </div>
        <div className={classes.circleBox}>
          <div className={classes.experienceCircle}>
            <p>Experience</p>
          </div>
        </div>
        <div className={classes.circleBox}>
          <div className={classes.educationCircle}>
            <p>Education</p>
          </div>
        </div>
        <div className={classes.circleBox}>
          <div className={classes.experienceCircle}>
            <p>Experience</p>
          </div>
        </div>
        <div className={classes.circleBox}>
          <div className={classes.skillsCircle}>
            <p>Skills</p>
          </div>
        </div>
        <div className={classes.circleBox}>
          <div className={classes.experienceCircle}>
            <p>Experience</p>
          </div>
        </div>
        <div className={classes.circleBox}>
          <div className={classes.languagesCircle}>
            <p>Languages</p>
          </div>
        </div>
        <div className={classes.circleBox}>
          <div className={classes.softSkillsCircle}>
            <p>Soft Skills</p>
          </div>
        </div>
        <div className={classes.circleBox}>
          <div className={classes.activitiesCircle}>
            <p>Activities</p>
          </div>
        </div>
      </div> */}
    </section>
  );
}

export default AboutPage;
